<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handleOk"
      @cancel="cancle"
      wrapClassName="cancelNamewww"
      :width="842"
      :closable="false"
      centered
      :maskClosable="false"

    >
      <a-spin :spinning="pageLoadFlag">
        <div class="modal_close" @click="cancle">
          <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
        </div>
        <div class="modal_title_box">
        <span class="modal_title">
          <i class="modal_title_line"></i>填写跟进记录
        </span>
        </div>
        <div class="cancelContent">
          <div class="selectBox">
            <div class="everyBox">
              <p class="lable">跟进方式：</p>
              <template>
                <a-auto-complete
                  style="width: 300px!important"
                  class="chose-account"
                  placeholder="请选择跟进方式"
                  v-model="GJid"
                  :value="GJid"
                  :data-source="gjList"
                  :filter-option="false"
                  :defaultActiveFirstOption="true"
                >
                  <a-spin slot="notFoundContent" size="small" />

                  <template slot="dataSource">
                    <a-select-option
                      style="width: 300px!important"
                      :value="item.name"
                      v-for="(item,index) in gjList"
                      :key="index"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </template>
                </a-auto-complete>
              </template>
            </div>
            <div class="everyBox therem">
              <p class="lable">跟进结果：</p>
              <a-textarea style="width: 400px"
                          placeholder="请输入本次跟进结果(不超过800字)"
                          :auto-size="{ minRows: 3, maxRows: 30 }"
                          v-model="result"></a-textarea>
            </div>
            <div class="everyBox">
              <p class="lable">上传图片：</p>
              <div class="imgUpload">
                <a-upload
                  list-type="picture-card"
                  :file-list="fileList"
                  :before-upload="beforeUpload"
                  @change="handleChange"
                  :remove="handleRemove"
                  @preview="handlePreview"
                  style="display: flex;flex-direction: row-reverse"
                >
                  <div v-if="fileList.length<3">
                    <a-icon type="upload" />
                  </div>
                </a-upload>
                <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                  <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
              </div>
            </div>
            <div class="everyBox" style="margin-top:16px">
              <p class="lable">更改意向状态：</p>
              <a-radio-group name="radioGroup" class="radioGroup"
                             v-model="yxstatus">
                <a-radio :value="2">
                  消失
                </a-radio>
                <a-radio :value="3">
                  继续
                </a-radio>
                <a-radio :value="1">
                  成交
                </a-radio>
              </a-radio-group>

            </div>
            <div class="everyBox bottomBox">
              <p  class="lable">用户购买意向：</p>
              <div class="rightBox">
                <div class="proBox"
                     v-for="(item,index) in productList"
                     :key="item.id"
                >
                  <div class="proItemBox">
                    <a-select
                      class="chose-account"
                      show-search
                      option-filter-prop="children"
                      :filter-option="filterOption"
                      v-model="item.spartId"
                      placeholder="请选择品类"
                      @change="handleChangespart($event,item)"
                    >
                      <a-select-option
                        :value="items.code"
                        v-for="items in spartList"
                        :key="items.code"
                      >{{ items.name }}
                      </a-select-option>
                    </a-select>
                    <a-auto-complete
                      class="chose-account2"
                      placeholder="请输入产品信息"
                      show-search
                      v-model="item.product"
                      :value="item.product"
                      :data-source="item.proList"
                      :filter-option="false"
                      :defaultActiveFirstOption="true"
                      @search="getProduct(item,$event)"
                      style="width: 240px"

                    >
                      <a-spin v-if="item.fetching" slot="notFoundContent" size="small" />
                      <template slot="dataSource">
                        <a-select-option
                          :value="item"
                          v-for="(item) in item.proList"
                          :key="item"
                        >
                          {{ (item).trim() }}
                        </a-select-option>
                      </template>
                    </a-auto-complete>
                    <a-select
                      class="chose-account"
                      show-search
                      option-filter-prop="children"
                      :filter-option="filterOption"
                      @change="handleChangeBudget(item)"
                      v-model="item.budget"
                      placeholder="请选择购买预算"
                    >
                      <a-select-option
                        :value="item.name"
                        v-for="item in budgetList"
                        :key="item.code"
                      >{{ item.name }}
                      </a-select-option>
                    </a-select>
                    <a-date-picker class="chose-account2"
                                   @change="(data,dateString)=>changeTimejh(data,dateString,item)"
                                   placeholder="请选择计划购买时间"
                                   v-model="item.planBuyTimeStr" />
                  </div>
                  <div class="close">
                    <a-popconfirm
                      placement="topRight"
                      title="确认删除该产品？"
                      ok-text="确认"
                      cancel-text="取消"
                      @confirm="delectProItem(item,index)"
                    >
                      <i class="iconfont icon-guanbi-gouwuche iconfontgwc"></i>
                    </a-popconfirm>
                  </div>
                </div>
                <div class="addPro" @click="addProItem">
                  <img src="@/assets/baseInfo/录入添加@2x.png">新增意向产品
                </div>

              </div>

            </div>
            <div class="everyBox biaoqian laiyuan">
              <div class="biaoqianBox">
                <p class="lable" style="margin-top:4px">用户标签：</p>
                <div class="userSorce">
                  <div class="smallSorce"
                       v-for="(item,index)  in  userTipsList"
                       :key="index"
                       @click="changeTipsBtn(item,'0')"
                       :class="item.checked?'smallSorceActice':'smallSorce'"
                  >
                    {{ item.label }}
                  </div>
                  <div class="circleBox" @click="openvisibleTips">
                    <img src="@/assets/baseInfo/录入添加@2x.png">
                  </div>
                </div>

              </div>
              <p class="tips">不可超过8个</p>
            </div>
            <div class="everyBox">
              <p class="lable">计划跟进时间：</p>
              <a-date-picker
                style="width: 300px"
                class="chose-account"
                @change="(data,dateString)=>changeTime(data,dateString)"
                placeholder="请选择下次跟进时间"
                v-model="planTimeStr" />
            </div>
            <div class="everyBox">
              <p class="lable">计划跟进方式：</p>
              <template>
                <a-auto-complete
                  style="width: 300px!important"
                  class="chose-account"
                  placeholder="请选择跟进方式"
                  v-model="planGJId"
                  :value="planGJId"
                  :data-source="gjList"
                  :filter-option="false"
                  :defaultActiveFirstOption="true"
                >
                  <a-spin slot="notFoundContent" size="small" />

                  <template slot="dataSource">
                    <a-select-option
                      style="width: 300px!important"
                      :value="item.name"
                      v-for="(item,index) in palngjList"
                      :key="index"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </template>
                </a-auto-complete>
              </template>
            </div>
            <div class="everyBox therem">
              <p class="lable">计划跟进主题：</p>
              <a-textarea style="width: 400px"
                          placeholder="请输入本次跟进主题(不超过800字)"
                          :auto-size="{ minRows: 3, maxRows: 30 }"
                          v-model="theme"></a-textarea>
            </div>
          </div>
        </div>
      </a-spin>
    </a-modal>
    <!--用户标签  -->
    <a-modal
      v-model="visibleTips"
      @ok="TipsOk"
      @cancel="clickTIPs"
      wrapClassName="cancelNameTips"
      :width="648"
      :closable="false"
      centered
      :maskClosable="false"
    >
      <a-spin :spinning="pageLoadFlagbq">
        <div class="modal_close" @click="clickTIPs">
          <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
        </div>
        <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>新建用户标签
      </span>
        </div>
        <div class="cancelContent">
          <div
            v-for="(item,index) in therrr"
            :key="index"
            @click="changeTipsBtn(item,'1')"
            :class="item.checked?'clickBtnActive':'clickBtn'"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="cancelInfo">
          <span class="cancleZi">自定义：</span>
          <a-input
            placeholder="请输入用户标签"
            :value="tipsArea"
            v-model="tipsArea"
          />
        </div>
        <div class="cancelInfo">
          <span class="cancleZi">标签说明：</span>
          <a-input
            placeholder="请输入用户标签说明"
            :value="userTip"
            v-model="userTip"
          />
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>
<script>

import { publicPath } from "@/common/constant";
import axios from "axios";
import {
  findListLabel,
  getDefevtiveProByMatkl,
  potentialSpart,
  saveInfoLabel
} from "@/views/userOperations/IntendedUserEntry/api";
import { focusonremarkplz } from "@/views/basicInformation/components/newMerchantaddEdit/api";
import { getQuestionTypes } from "@/views/questionnaire/components/api";
import { getUser, updateShopPotentialVisit } from "@/views/userOperations/IntentionDetail/components/saleChnge/api";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  components: {},
  data() {
    return {
      gjList:[],// 跟进方式
      pageLoadFlag: false,// 整个页面的loading
      previewVisible: false,
      GJid:undefined,// 跟进方式id
      planGJId:undefined,// 计划跟进方式
      planTimeStr:'', //计划跟近时间
      result:'',//跟进结果
      fileList:[],
      fileIds:[],// 附件id
      urlArr:[],// 附件地址
      previewImage: '',
      yxstatus:undefined,// 意向状态
      // 产品信息
      productList: [
        {
          id: "",
          spartId: undefined,
          product: "",
          budget: undefined,
          planBuyTimeStr: "",
          proList: [],
          fetching: false,
          spartName:'',
        }
      ],
      spartList: [],   // 意向品类
      budgetList: [],// 购买预算
      addressHx: [],// 回显数据
      visibleTips: false,// 新建用户标签弹窗
      therrr:[],
      TipsIdTC:[],
      userTip:'',
      tipsArea:'',
      pageLoadFlagbq:false,
      userTipsList:[],
      TipsIds:[],//
      TipsIdName:[],
      theme:'',// 主题
      palngjList:[],// 计划跟进方式
      // outId:''

    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    userId: {
      type: String,
      default: ""
    },
    outId: {
      type: String,
      default: ""
    },
    efitInfo: {
      type: Object,
      default: function() {
        return {};
      }
    },
    status:{
      type: Number,
      default: 2
    },
  },

  watch: {
    efitInfo:function(val, old) {
      // this.outId = val.id
      // 如果接口里销售机会列表大于0 就给本地的赋值
      if (val.detailList.length > 0) {
        this.productList = [];
        val.detailList.forEach(item => {
          this.productList.push({
            id: item.id,
            product: item.product,
            spartName:item.spartName,
            budget: item.budget,
            spartId: item.spartId,
            planBuyTimeStr: item.planBuyTimeStr
          });
        });
      } else {
        this.productList = [
          {
            id: "",
            spartId: undefined,
            product: "",
            budget: undefined,
            planBuyTimeStr: "",
            proList: [],
            fetching: false,
            spartName:'',
          }
        ]
      }
    },
    visible:function(val, old) {
      if(val) {
        this.getUserInfo();
        this.urlArr = []
        if(this.efitInfo) {
          // this.outId = this.efitInfo.id
          this.productList = [];
          if( this.efitInfo.detailList.length > 0) {
            this.efitInfo.detailList.forEach(item => {
              this.productList.push({
                id: item.id,
                product: item.product,
                spartName:item.spartName,
                budget: item.budget,
                spartId: item.spartId,
                planBuyTimeStr: item.planBuyTimeStr
              });
            });
          } else {
            this.productList = [
              {
                id: "",
                spartId: undefined,
                product: "",
                budget: undefined,
                planBuyTimeStr: "",
                proList: [],
                fetching: false,
                spartName:'',
              }
            ]
            this.urlArr = []
          }

        }
      }
    },
    status:function(val,old) {
      if(val!==100) {
        this.yxstatus = val
      } else {
        this.yxstatus = undefined
      }

    }
  },
  mounted() {
    this.getmaterialGrouplist();
    this.getBuyList();

    this.getGjTypes()

  },
  methods: {
    getGjTypes() {
      let data = {
        pid: 17378751205
      }
      getQuestionTypes(data).then(res => {
        this.gjList = res.data.list;
        this.GJid =  '电话';
        this.palngjList = res.data.list;
        this.planGJId =  '';

      })
    },
    // 顶部用户信息
    getUserInfo() {
      let data = {
        userId: this.userId
      };
      getUser(data).then(res => {
        if (res.data.code == 0) {
          this.TipsIds = res.data.data.labelList;
          this.getuserTipList();
        }

      });
    },
    // 标签确定
    TipsOk() {
      if (!this.tipsArea) {
        this.TipsIds = this.TipsIdTC
        if(this.userTipsList.length>0 &&this.TipsIds.length > 0 ) {
          this.userTipsList.forEach((item) => {
            item.checked = false;
            this.TipsIds.forEach((element) => {
              if (item.id == element) {
                item.checked = true;
              }
            });
          })
        } else if(this.userTipsList.length>0 && this.TipsIds.length <= 0 ){
          this.userTipsList.forEach((item) => {
            item.checked = false;
          })
        }
        this.$forceUpdate()
        this.visibleTips = false;
        return;
      }
      if (this.TipsIds.length < 8) {
        let data = {
          custInfoId: this.$store.state.user.userInfo.customer.id,
          type: 2,
          label: this.tipsArea,
          remark: this.userTip
        };
        this.pageLoadFlagbq = true;
        saveInfoLabel(data).then(res => {
          if (res.data.code == 0) {
            this.userTipsList.push(
              {
                id: res.data.data,
                name: this.tipsArea,
                checked: true
              }
            );
            this.TipsIds.push(res.data.data);
            this.TipsIdTC.push(res.data.data);
            this.tipsArea = "";
            this.remark = "";
            this.userTip = "";
            this.getuserTipList();
            this.pageLoadFlagbq = false;
            this.visibleTips = false;
          } else {
            this.$message.warning(res.data.msg);
            this.tipsArea = "";
            this.remark = "";
            this.pageLoadFlagbq = false;
            this.visibleTips = false;
          }
        });
      } else {
        this.$message.warning("已选中8个标签，不能再新增");
        return;
      }
    },
    clickTIPs() {
      this.TipsIdTC = this.TipsIds
      this.$forceUpdate()
      if(this.userTipsList.length>0 &&this.TipsIds.length > 0 ) {
        this.userTipsList.forEach((item) => {
          item.checked = false;
          this.TipsIds.forEach((element) => {
            if (item.id == element) {
              item.checked = true;
            }
          });
        })
      } else if( this.userTipsList.length>0 &&this.TipsIds.length <= 0) {
        this.userTipsList.forEach((item) => {
          item.checked = false;
        })
      }
      this.visibleTips = false;
    },
    changeTipsBtn(item,type) {
      if(type == 0) {
        if (!item.checked) {
          if (this.TipsIds.length >= 8) {
            this.$message.warning("用户标签选择不能超过8个");
            return;
          }

        }
        item.checked = !item.checked;
        this.$forceUpdate();
        this.TipsIds = [];
        this.userTipsList.forEach(items => {
          if (items.checked == true) {
            this.TipsIds.push(items.id);
          }
        });
        this.$forceUpdate();
      } else {
        if (!item.checked) {
          if (this.TipsIdTC.length >= 8) {
            this.$message.warning("用户标签选择不能超过8个");
            return;
          }
        }
        item.checked = !item.checked;
        this.$forceUpdate();
        this.TipsIdTC = [];
        this.therrr.forEach(items => {
          if (items.checked == true) {
            this.TipsIdTC.push(items.id);
          }
        });
        this.$forceUpdate();
      }
    },
    // 打开用户标签弹窗
    openvisibleTips(){
      this.TipsIdTC = this.TipsIds
      this.$forceUpdate()
      if(this.therrr.length>0 &&this.TipsIdTC.length > 0 ) {
        this.therrr.forEach((item) => {
          item.checked = false;
          this.TipsIdTC.forEach((element) => {
            if (item.id == element) {
              item.checked = true;
            }
          });
        })
      }
      this.visibleTips = true
      this.$forceUpdate()
    },
    // 获取用户标签
    getuserTipList() {
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findListLabel(data).then(res => {
        this.userTipsList = res.data.data;
        this.therrr = res.data.data;
        if (res.data.data.length > 0 && this.TipsIds.length > 0) {
          this.userTipsList.forEach((item) => {
            item.checked = false;
            this.TipsIds.forEach((element) => {
              if (item.id == element) {
                item.checked = true;
              }
            });
          })
          this.$forceUpdate()
        } else if (this.userTipsList > 0) {
          this.userTipsList.forEach((item) => {
            item.checked = false;
          });
        }
      });
    },


    // 获取产品
    getProduct(item, value) {
      let data = {
        term: value
      };
      item.fetching = true;
      getDefevtiveProByMatkl(data).then(res => {
        if (res.data.list.length > 0 && res.data.list !== null) {
          item.proList = [...new Set(res.data.list)]; //缓存列表数据，前端分页
        } else {
          item.proList = [];
        }
        item.fetching = false;
        this.$forceUpdate()
      });
    },
    // 新增产品
    addProItem() {
      this.productList.push({
        id: "",
        spartId: undefined,
        spartName:'',
        product: "",
        budget: undefined,
        planBuyTimeStr: "",
        proList: [],
        fetching: false
      });

    },
    // 商品行数据删除
    delectProItem(item, index) {
      // 如果当前产品行数据 大于1 就减去  只剩一个 就清空
      if (this.productList.length > 1) {
        this.productList.splice(index, 1);
      } else {
        this.productList.forEach(item => {
          item.id = "";
          item.spartId = undefined;
          item.spartName = '',
          item.product = "";
          item.budget = undefined;
          item.planBuyTimeStr = "";
          item.proList = [];
          item.fetching = false;
        });
      }

    },
    // 获取意向品类
    getmaterialGrouplist() {
      let data = {};
      potentialSpart(data).then(res => {
        if (res.data.code == 0) {
          this.spartList = res.data.list;
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    // 获取购买意向接口
    getBuyList() {
      let data = {
        pid: 14927471376
      };
      focusonremarkplz(data).then(res => {
        this.budgetList = res.data.list;
      });
    },
    // 修改意向品类
    handleChangespart(e,item) {
      let obj = this.spartList.find((i)=>{
        return i.code === e;//筛选出匹配数据
      });
      item.spartName = obj.name
    },
    // 修名称
    changeProName(item) {
    },
    // 修改预算
    handleChangeBudget(item) {
    },
    // 修改产品信息时间
    changeTimejh(date, dateString, item) {
      item.planBuyTimeStr = dateString;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      const formData = new FormData();
      formData.append("file1", file);
      formData.append("fileType", "shopPotentialUser");
      // 上传接口/custAgentActivity/uploadFile.nd
      let config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      // 发送请求
      this.Canbutton = true
      const BASE_API = publicPath;
      axios.post(BASE_API + '/comm/method=uploadFile.nd', formData, config).then(res => {
        if (res.data.message == '上传成功') {
          this.fileIds.push({
            id: res.data.businessId
          })
          this.urlArr.push( res.data.url)
        } else {
          this.$message.warning('上传失败', res.data.message)
        }
        this.Canbutton = false
      })
      return false;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.fileIds.splice(index, 1)
      this.urlArr.splice(index, 1);
    },

    // 修改产品信息时间
    changeTime(date, dateString) {
      this.planTimeStr = dateString;
    },

    handleOk() {
      // this.TipsIds
      this.TipsIdName  = []
     if( this.TipsIds.length > 0) {
        this.TipsIds.forEach(item=>{
          let obj = this.userTipsList.find((i)=>{
            return i.id === item;//筛选出匹配数据
          });
          this.TipsIdName.push(obj)
        })
      }

      var namaList = []
      if(this.TipsIdName.length > 0) {
        this.TipsIdName.forEach(items=>{
          namaList.push({
            labelId:items.id,
            userType:items.type,
            labelName:items.label
          })
        })
      }

      // 数组重组
      var ppRouList = [];
      this.productList.forEach((item, index) => {
        ppRouList.push({
          // item.id ? item.id :
          //  id: "",
          id: item.id ? item.id :null,
          spartId: item.spartId ? item.spartId : "",
          spartName: item.spartName ? item.spartName : "",
          product: item.product ? item.product : "",
          budget: item.budget ? item.budget : "",
          planBuyTimeStr: item.planBuyTimeStr ? item.planBuyTimeStr : ""
        });
      });

      for (var i = 0; i < ppRouList.length; i++) {
        if (!ppRouList[i].spartId) {
          this.$message.warning(`第${i + 1}行品类不能为空,请选择`);
          return;
        }
      }
      this.pageLoadFlag = true
      let data = {
        visitWayName:null,// 传空
        visitResult:null,// 传空
        id: this.outId,// 意向产品外层id（前一个页面传过来）
        finish:	1,// 去跟进去回访传1
        pictureId: this.urlArr.length>0? this.urlArr.join(','):null,// 图片路径字符串 ，隔开
        finishVisitWayName:this.GJid?this.GJid.replace(/\s*/g,""):null,// 计划方式名称
        finishVisitResult:this.result?this.result:null,// 跟进结果
        shopPotentialUserId	:this.userId,// 用户id
        visitDateNew:this.planTimeStr?this.planTimeStr:null,//		计划日期()
        visitResultNew:this.theme,//计划主题（400字以内）()
        visitWayNameNew:this.planGJId?this.planGJId.replace(/\s*/g,""):null,
        shopPotential : {		//意向商品信息
          status:this.yxstatus?this.yxstatus:null,// 意向状态 1成交。2消失。3继续
          detailList:ppRouList// 产品信息 不需要传id
        },
        userLables:namaList,// 标签集合
        "user": {
          "id": this.userId,// 用户id
        },
        visitType:2, //跟进2 回访1
      };
      updateShopPotentialVisit(data).then(res=>{
        if(res.data.code == 0) {
          this.$message.success(res.data.msg,3)
          this.fileIds = []
          this.urlArr=[]
          this.fileList = []
          this.GJid =  '电话'
          this.result = ''
          this.planGJId =  ''
          this.planTimeStr = ''
          this.remark=''
          this.theme = ''
          this.productList = []
          this.TipsIdName = []
          this.TipsIds = []
          this.pageLoadFlag = false
          this.$emit("update:visible", false);
          this.$emit("cancelReasonHandOk", data);
          this.$forceUpdate()
        } else {
          this.$message.warning(res.data.msg,3)
          this.pageLoadFlag = false
        }
      }).catch(error=>{
        this.showLoading = false
      })
      // this.$emit("update:visible", false);
      // this.$emit("cancelReasonHandOk", data);
      // this.$forceUpdate()

    },
    cancle() {
      this.urlArr=[]
      this.fileIds = []
      this.fileList = []
      this.GJid =  '电话'
      this.result = ''
      this.planGJId =  ''
      this.planTimeStr = ''
      this.remark=''
      this.theme = ''
      this.productList = []
      this.TipsIdName = []
      this.TipsIds = []
      this.$emit("update:visible", false);
      this.$forceUpdate()

    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    }

  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelNamewww {
  .ant-input{
    border: 1px solid #eee!important;
    width: 300px;
  }
 .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      padding: 30px 0px 0 0px;

      .cancelContent {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
        max-height:600px;
        overflow:auto;


        .selectBox {
          //margin-top: 16px;
          display: flex;
          flex-direction: column;

          .everyBox {
            display: flex;
            align-items: center;
            margin-left: 78px;
            margin-top: 24px;

            .lable {
              width: 98px;
              margin-right: 8px;
              text-align: right;
              font-size: 14px;
              color: #262626;

              span {
                font-size: 14px;
                font-weight: 400;
                color: #D9161C;
              }
            }
            .imgUpload {
              /deep/.anticon{
                font-size: 22px;
              }
            }
            /deep/.ant-select-auto-complete.ant-select .ant-input {
              border-width: 0px!important;
              width: 300px!important;
            }
            .ant-select-auto-complete.ant-select .ant-input {
              border-width: 0px!important;
              width: 300px!important;
            }
            /deep/ .ant-select-selection {
              width: 300px;
              height: 32px;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid #EEEEEE;
            }

            .aInput {
              width: 416px !important;
              height: 32px !important;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid #EEEEEE;
              color: #262626;
            }

            .userInput {
              width: 200px;
              margin-right: 16px;
            }

            .radioGroup {
              margin-left: 8px;
            }

            .userSorce {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              //align-items: center;
              max-width: 530px;

              .smallSorce {
                padding: 0 14px 0 14px;
                color: #00AAA6;
                height: 32px;
                border-radius: 16px;
                border: 1px solid #EEEEEE;
                text-align: center;
                line-height: 32px;
                margin-right: 8px;
                margin-bottom: 12px;
                cursor: pointer;
              }

              .smallSorceActice {
                cursor: pointer;
                height: 32px;
                background: #00AAA6;
                border-radius: 16px;
                text-align: center;
                line-height: 32px;
                padding: 0 14px 0 14px;
                color: #fff;
                margin-right: 8px;
                margin-bottom: 12px;
                //border: none;
              }
            }

            .circleBox {
              width: 32px;
              height: 32px;
              border-radius: 16px;
              border: 1px solid #EEEEEE;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              img {
                width: 12px;
                height: 12px;
              }
            }

          }
          .therem {
            display: flex;
            align-items: flex-start;
            /deep/ textarea.ant-input {
              border: 1px solid #EEEEEE;
            }
          }
          .bottomBox {
            display: flex;
            //max-height: 300px;
            //overflow: auto;
            align-items: flex-start;
            //flex-direction: row;



            p {
              width: 104px;
              font-size: 14px;
              font-weight: 400;
              color: #262626;
              margin-top: 18px;
              text-align: right;

              span {
                color: #D9161C;
              }
            }

            .rightBox {
              margin-left: 8px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              .proBox {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                /deep/ .ant-select-selection {
                  width: 180px;
                  height: 32px;
                  background: #FFFFFF;
                  border-radius: 4px;
                  border: 1px solid #EEEEEE;
                }
                .proItemBox {
                  width: 468px;
                  height: 112px;
                  background: #F2F3F5;
                  border-radius: 6px;
                  margin-bottom: 16px;

                  .chose-account {
                    width: 180px;
                    height: 32px;
                    margin: 16px 16px 0px 16px;
                  }

                  .chose-account2 {
                    /deep/ .ant-select-selection {
                      width: 240px;
                      height: 32px;
                      background: #FFFFFF;
                      border-radius: 4px;
                      border: 1px solid #EEEEEE;
                    }
                    width: 240px;
                  }
                  .ant-input {
                    width: 240px;
                    height: 32px;
                    overflow: auto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                  }
                }

                .close {
                  margin-left: 16px;
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  color: #AAAAAA;
                  cursor: pointer;

                  .iconfontgwc {
                    margin-top: -10px;
                    font-size: 20px;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                  }

                  .iconfontgwc:hover {
                    margin-top: -10px;
                    // background-color: #00AAA6;
                    // margin-top: -4px;
                    background-image: url('./../../../../assets/cart/close.png');
                    color: #fff;
                    background-size: 100%;

                  }

                }
              }

              .addPro {
                display: flex;
                justify-content: center;
                //align-items: center;
                width: 418px;
                height: 32px;
                border-radius: 4px;
                border: 1px dashed #EEEEEE;
                cursor: pointer;
                margin-left: -35px;


                img {
                  width: 8px;
                  height: 8px;
                  margin-right: 4px;
                  margin-top: 12px;
                }

                font-size: 14px;
                font-weight: 400;
                color: #1890FF;
                line-height: 32px;
              }

              .addPro:hover {
                color: #1890FF;
                border: 1px dashed #1890FF;
                background: rgba(24, 144, 255, 0.08);
              }


            }

          }
          .everyBox:first-child{
            margin-top: 40px;
          }

          .proBox {
            display: flex;
            flex-direction: column;
            justify-content: flex-start !important;
            align-items: flex-start;

            .prooneBox {
              display: flex;

              .prouctBox {
                display: flex;
                flex-direction: column;

                .proItem {
                  display: flex;
                  margin-bottom: 16px;
                  align-items: center;

                  /deep/ .ant-select-selection {
                    width: 180px;
                    height: 32px;
                    background: #FFFFFF;
                    border-radius: 4px;
                    border: 1px solid #EEEEEE;
                  }

                }
              }
            }

            .addNEWbTN {
              cursor: pointer;
              width: 180px;
              height: 32px;
              border-radius: 3px;
              border: 1px dashed #EEEEEE;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #1890FF;
              margin-left: 332px;

              img {
                width: 12px;
                height: 12px;
                margin-right: 4px;
              }
            }

          }

          .biaoqian {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .biaoqianBox {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start
            }

            .tips {
              font-size: 12px;
              font-weight: 400;
              color: #AAAAAA;
              margin-left: 109px;
              margin-top: -6px;
            }

          }
          .laiyuan {
            margin-top: 24px;
          }

          .adress {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            div {
              display: flex;
            }

            .userDsInputress {
              margin-top: 16px;
              margin-left: 106px;
              width: 500px;
            }
          }
        }


        .ant-tree-node-selected {
          background-color: red;
        }

        .ant-form {
          line-height: 32px;
        }

        .ant-form-item-control-wrapper {
          margin-left: 16px;
        }

        .ant-form-item-control {
          line-height: 32px;
        }

        .ant-form-item-label {
          line-height: 32px;
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        .messageInfo {
          font-size: 12px;
          font-weight: 400;
          color: #AAAAAA;
          line-height: 12px;
          margin-top: 8px;
          margin-left: 86px;
        }
      }



      .modal_close {

        position: absolute;
        width: 42px;
        height: 42px;
        top: -30px;
        right: 0;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        //padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:44px 0px  30px 0;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }

}

/deep/ .cancelNameYHLY {
  font-family: PingFangSC-Medium, PingFang SC;

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding-bottom: 0px;
      .cancelContent {
        .btnGroup {
          display: flex;
          flex-wrap: wrap;
          display: flex;
          justify-content: flex-start;
        }

        display: flex;
        justify-content: center;
        width: 470px;

        .clickBtn {
          cursor: pointer;
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #00AAA6;
          margin-right: 8px;
          margin-bottom: 12px;
        }

        .clickBtnActive {
          cursor: pointer;
          margin-right: 8px;
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          background: #00AAA6;
          justify-content: center;
          align-items: center;
          color: #fff;
          margin-bottom: 12px;
        }
      }

      .cancelInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 418px;
        //margin-bottom: 36px;
        margin-top: 12px;
        text-align: left;
        overflow: hidden;

        .cancleZi {
          width: 56px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px
        }

        .ant-input {
          width: 300px;
          height: 32px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .ant-input-affix-wrapper {
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:44px 0px  30px 0;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}

/deep/ .cancelNameTips {
  font-family: PingFangSC-Medium, PingFang SC;

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding-bottom: 0px;
      .cancelContent {
        display: flex;
        flex-wrap: wrap;
        width: 418px;

        .clickBtn {
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #00AAA6;
          margin-right: 8px;
          margin-bottom: 12px;
          cursor: pointer;
        }

        .clickBtnActive {
          cursor: pointer;
          margin-right: 8px;
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          background: #00AAA6;
          justify-content: center;
          align-items: center;
          color: #fff;
          margin-bottom: 12px;
        }
      }

      .cancelInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 418px;
        //margin-bottom: 24px;
        text-align: left;
        overflow: hidden;
        margin-top: 12px;

        .cancleZi {
          width: 70px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          margin-right: 8px;
          text-align: right;
        }

        .ant-input {
          width: 300px;
          height: 32px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .ant-input-affix-wrapper {
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: -24px;
        right: -114px;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:44px 0px  30px 0;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}
</style>


