<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handleOk"
      @cancel="cancle"
      wrapClassName="cancelNameaaaaaa"
      :width="648"
      :closable="false"
      centered
      :maskClosable="false"

    >
      <a-spin :spinning="pageLoadFlag">
        <div class="modal_close" @click="cancle">
          <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
        </div>
        <div class="modal_title_box">
          <span class="modal_title">
            <i class="modal_title_line"></i>制定跟进计划
          </span>
        </div>
        <a-spin  :spinning="showLoading">
          <div class="cancelContent">
            <div class="selectBox">
              <div class="everyBox">
                <p class="lable"><span style="color:#D9161C">*</span>计划跟进时间：</p>
                <a-date-picker
                  style="width: 300px"
                  class="chose-account"
                  @change="(data,dateString)=>changeTime(data,dateString)"
                  placeholder="请选择下次跟进时间"
                  v-model="planTimeStr" />
              </div>
              <div class="everyBox">
                <p class="lable">计划跟进方式：</p>
                <template>
                  <a-auto-complete
                    class="chose-account"
                    placeholder="请选择跟进方式"
                    v-model="FllowMethosdId"
                    :value="FllowMethosdId"
                    :data-source="gjList"
                    :filter-option="false"
                    :defaultActiveFirstOption="true"
                    @search="handleChangespart"
                  >
                    <a-spin slot="notFoundContent" size="small" />

                    <template slot="dataSource">
                      <a-select-option
                        :value="item.name"
                        v-for="(item,index) in gjList"
                        :key="index"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </template>
                  </a-auto-complete>
                </template>
              </div>
              <div class="everyBox three">
                <p class="lable">计划跟进主题：</p>
                <a-textarea style="width: 400px"
                            placeholder="请输入本次跟进主题(不超过800字)"
                            :auto-size="{ minRows: 3, maxRows: 30 }"
                            v-model="remark"></a-textarea>
              </div>
            </div>
          </div>
        </a-spin>

      </a-spin>
    </a-modal>
  </div>
</template>
<script>

import { getQuestionTypes } from "@/views/questionnaire/components/api";
import { saveShopPotentialVisit } from "@/views/userOperations/IntentionDetail/components/saleChnge/api";

export default {
  components: {},
  data() {
    return {
      gjList:[],
      pageLoadFlag: false,// 整个页面的loading
      FllowMethosdId:undefined,
      planTimeStr:'',
      remark:'',
      showLoading:false

    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    userId: {
      type: String,
      default: ""
    },
    outId: {
      type: String,
      default: ""
    },

  },

  watch: {



  },
  mounted() {
    this.getGjTypes()

  },
  methods: {
    getGjTypes() {
      let data = {
        pid: 17378751205
      }
      getQuestionTypes(data).then(res => {
        this.gjList = res.data.list;
        this.FllowMethosdId =  '电话';
      })
    },
    // 修改产品信息时间
    changeTime(date, dateString) {
      this.planTimeStr = dateString;
    },
    handleChangespart(e) {
    },
    handleOk() {
      this.showLoading = true
      let data = {
        shopPotentialUserId	:this.userId,
        visitType:2,
        visitDate:this.planTimeStr,
        visitResult:this.remark,
        // 意向表id
        shopPotentialId:this.outId,
        shopPotentialBuyId:'',		// 已购买表id（回访时必填，跟进时空值）
        visitWayName:this.FllowMethosdId?this.FllowMethosdId.replace(/\s*/g,""):''
      };
      saveShopPotentialVisit(data).then(res=>{
        if(res.data.code == 0) {
          this.$message.success(res.data.msg,3)
          this.$emit("update:visible", false);
          this.planTimeStr = ''
          this.remark=''
          this.FllowMethosdId = '电话'
          this.showLoading = false
          this.$emit("cancelReasonHandOk", data);
          this.$forceUpdate()
        } else {
          this.$message.warning(res.data.msg,3)
          this.showLoading = false
        }

      }).catch(error=>{
        this.showLoading = false
      })


    },
    cancle() {
      this.planTimeStr = ''
      this.remark=''
      this.FllowMethosdId = '电话'
      this.$emit("update:visible", false);
      this.$forceUpdate()

    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    }

  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelNameaaaaaa {
  .ant-input{
    border: 1px solid #eee!important;
  }
  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      padding: 30px 0px 0 0px;

      .cancelContent {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;


        .selectBox {
          //margin-top: 16px;
          display: flex;
          flex-direction: column;

          .everyBox {
            display: flex;
            align-items: center;
            margin-left: 78px;
            margin-top: 24px;

            .lable {
              width: 108px;
              margin-right: 8px;
              text-align: right;
              font-size: 14px;
              color: #262626;

              span {
                font-size: 14px;
                font-weight: 400;
                color: #D9161C;
              }
            }
            /deep/.ant-select-auto-complete.ant-select .ant-input {
              //border-width: 0px!important;
              width: 300px!important;
              border-color: #eee;;
            }
            .ant-select-auto-complete.ant-select .ant-input {
              width: 300px!important;
              border-color: #eee;;
            }
            .ant-select-selection--single {
              width: 300px;
            }

            /deep/ .ant-select-selection {
              width: 300px!important;
              height: 32px;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid #EEEEEE;
            }

            .aInput {
              width: 416px !important;
              height: 32px !important;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid #EEEEEE;
              color: #262626;
            }

            .userInput {
              width: 200px;
              margin-right: 16px;
            }

            .radioGroup {
              margin-left: 8px;
            }

            .userSorce {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              //align-items: center;
              max-width: 530px;

              .smallSorce {
                padding: 0 14px 0 14px;
                color: #00AAA6;
                height: 32px;
                border-radius: 16px;
                border: 1px solid #EEEEEE;
                text-align: center;
                line-height: 32px;
                margin-right: 8px;
                margin-bottom: 12px;
                cursor: pointer;
              }

              .smallSorceActice {
                cursor: pointer;
                height: 32px;
                background: #00AAA6;
                border-radius: 16px;
                text-align: center;
                line-height: 32px;
                padding: 0 14px 0 14px;
                color: #fff;
                margin-right: 8px;
                margin-bottom: 12px;
                //border: none;
              }
            }

            .circleBox {
              width: 32px;
              height: 32px;
              border-radius: 16px;
              border: 1px solid #EEEEEE;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              img {
                width: 12px;
                height: 12px;
              }
            }

          }
          .three {
            /deep/ textarea.ant-input {
              border: 1px solid #EEEEEE;
            }
          }
          .everyBox:first-child{
            margin-top: 40px;
          }

          .proBox {
            display: flex;
            flex-direction: column;
            justify-content: flex-start !important;
            align-items: flex-start;

            .prooneBox {
              display: flex;

              .prouctBox {
                display: flex;
                flex-direction: column;

                .proItem {
                  display: flex;
                  margin-bottom: 16px;
                  align-items: center;

                  /deep/ .ant-select-selection {
                    width: 180px;
                    height: 32px;
                    background: #FFFFFF;
                    border-radius: 4px;
                    border: 1px solid #EEEEEE;
                  }

                }
              }
            }

            .addNEWbTN {
              cursor: pointer;
              width: 180px;
              height: 32px;
              border-radius: 3px;
              border: 1px dashed #EEEEEE;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #1890FF;
              margin-left: 332px;

              img {
                width: 12px;
                height: 12px;
                margin-right: 4px;
              }
            }

          }

          .biaoqian {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .biaoqianBox {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start
            }

            .tips {
              font-size: 12px;
              font-weight: 400;
              color: #AAAAAA;
              margin-left: 109px;
              margin-top: -6px;
            }

          }
          .laiyuan {
            margin-top: 12px;
          }

          .adress {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            div {
              display: flex;
            }

            .userDsInputress {
              margin-top: 16px;
              margin-left: 106px;
              width: 500px;
            }
          }
        }


        .ant-tree-node-selected {
          background-color: red;
        }

        .ant-form {
          line-height: 32px;
        }

        .ant-form-item-control-wrapper {
          margin-left: 16px;
        }

        .ant-form-item-control {
          line-height: 32px;
        }

        .ant-form-item-label {
          line-height: 32px;
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        .messageInfo {
          font-size: 12px;
          font-weight: 400;
          color: #AAAAAA;
          line-height: 12px;
          margin-top: 8px;
          margin-left: 86px;
        }
      }



      .modal_close {

        position: absolute;
        width: 42px;
        height: 42px;
        top: -30px;
        right: 0;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        //padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:44px 0px  30px 0;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }

}

/deep/ .cancelNameYHLY {
  font-family: PingFangSC-Medium, PingFang SC;

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding-bottom: 0px;
      .cancelContent {
        .btnGroup {
          display: flex;
          flex-wrap: wrap;
          display: flex;
          justify-content: flex-start;
        }

        display: flex;
        justify-content: center;
        width: 470px;

        .clickBtn {
          cursor: pointer;
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #00AAA6;
          margin-right: 8px;
          margin-bottom: 12px;
        }

        .clickBtnActive {
          cursor: pointer;
          margin-right: 8px;
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          background: #00AAA6;
          justify-content: center;
          align-items: center;
          color: #fff;
          margin-bottom: 12px;
        }
      }

      .cancelInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 418px;
        //margin-bottom: 36px;
        margin-top: 12px;
        text-align: left;
        overflow: hidden;

        .cancleZi {
          width: 56px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px
        }

        .ant-input {
          width: 300px!important;
          height: 32px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .ant-input-affix-wrapper {
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:44px 0px  30px 0;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}

/deep/ .cancelNameTips {
  font-family: PingFangSC-Medium, PingFang SC;

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding-bottom: 0px;
      .cancelContent {
        display: flex;
        flex-wrap: wrap;
        width: 418px;

        .clickBtn {
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #00AAA6;
          margin-right: 8px;
          margin-bottom: 12px;
          cursor: pointer;
        }

        .clickBtnActive {
          cursor: pointer;
          margin-right: 8px;
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          background: #00AAA6;
          justify-content: center;
          align-items: center;
          color: #fff;
          margin-bottom: 12px;
        }
      }

      .cancelInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 418px;
        //margin-bottom: 24px;
        text-align: left;
        overflow: hidden;
        margin-top: 12px;

        .cancleZi {
          width: 70px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          margin-right: 8px;
          text-align: right;
        }

        .ant-input {
          width: 300px!important;
          height: 32px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .ant-input-affix-wrapper {
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: -24px;
        right: -114px;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:44px 0px  30px 0;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}
</style>


